import React, { useState, useEffect } from 'react'
import './OverflowReport.scss'
import { Card, Table, Modal, Button, Form, FormControl, FormSelect } from 'react-bootstrap';
import Header from '../Header';
import SideBar from '../SideBar';
import { useParams } from 'react-router-dom';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';


function OverflowReport() {
  // getting the id and finding the apparment details
  const { id } = useParams();
  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  // houses within the apartment
  const [ctobTransactions, setCtobTransactions] = useState([])

  // get the houses and the tenants that are within that house
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  const [landlordApartment, setLandlordApartment] = useState([])
  const decodedToken = jwt_decode(accessToken);

  const userId = decodedToken.user_id;

  // from the localstorage get the logged in user and get the details of the selected apartment with the houses in the apartments and the tenants 
  useEffect(() => {
    // get the ctob transactions
    serverUrl.get(`/landlord/c2btranscation/?apartment=${id}`, config)
      .then((res) => {
        // Filter out the transactions with reconciled as true and overflow amount greater than 0
        const filteredTransactions = res.data.results.filter(transaction => transaction.overflow_amount > 0);
        // Set the filtered transactions as the value of setCtobTransactions
        setCtobTransactions(filteredTransactions);
        // console.log(filteredTransactions)
      })
      .catch((error) => {
        console.log(error);
      });

    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        // using the landlord id get the apartments linked to the landlord
        const landlordApartmentPromises = landlordIds.map((landlordId) => {
          return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
            .then((res) => res.data.results)
            .catch((error) => {
              console.log(error);
              return [];
            });
        });

        Promise.all(landlordApartmentPromises)
          .then((results) => {
            // i am merging the sub-arrays into a new array
            const apartments = results.flat();
            setLandlordApartment(apartments);
            // getting the houses in the apartment
            const houseCountPromises = apartments.map((apartment) => {
              return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                .then((res) => ({ apartmentId: apartment.id, houses: res.data.results }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id, houses: [] };
                });
            });


          })
          .catch((error) => {
            console.log(error);

          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  // searching function and sorting functionalities
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = ctobTransactions.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  // modal for adding reconciliation
  const [showModal, setShowModal] = useState(false)
  const [showModalPayment, setShowModalPayment] = useState(false)
  const [recordId, setRecordId] = useState('')

  const handleOpenModal = (record) => {
    setShowModal(true);
    setRecordId(record.id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleOpenModalPayment = (record) => {
    setShowModalPayment(true);
    setRecordId(record.id);
  };
  const handleCloseModalPayment = () => {
    setShowModalPayment(false);
    setShowModal(false);
    window.location.reload()
  };


  const updateReconcile = () => {
    updateRecord(recordId);
  }
  // done button reloading the page
  const handleCloseModalReload = () => {
    setShowModal(false);
    window.location.reload()
  };


  // add payments
  // handle when the house is selected
  const [houseSelectedId, setHouseSelectedId] = useState("");
  const [invoices, setInvoices] = useState([])
  const [invoiceSelectedId, setInvoiceSelectedId] = useState("");
  const [houseSelectedName, setHouseSelectedName] = useState("")
  const [apartmentHouse, setApartmentHouse] = useState([]);
  const [selectedApartmentId, setSelectedApartmentId] = useState("");
  const [selectedApartmentHouse, setSelectedApartmentHouse] = useState(null);
  const [selectedUtilities, setSelectedUtilities] = useState([])
  const [selectedHouseId, setSelectedHouseId] = useState(null);

  const monthNames = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
  ];


  function handleApartmentSelect(selectedApartmentId) {
    setSelectedApartmentId(selectedApartmentId)
    // get the houses to be listed when apartment is selected
    serverUrl
      .get(`/landlord/react-house-apartment-tenant/?apartment_id=${selectedApartmentId}`, config)
      .then((res) => {
        // Filter houses with occupancy status "vacant"
        const vacantHouses = res.data.results.filter((house) => house.status === 'occupied');

        // Set the filtered vacant houses in the state
        setApartmentHouse(vacantHouses);
      })
      .catch((error) => {
        // Handle the error, e.g., display an error message to the user
        console.log("Error fetching apartment data:", error);
        // You can also update state to indicate the error, if needed
      });

    // get the utilities to be displayed for that apartment
    serverUrl.get(`/landlord/expense/?apartment_id=${selectedApartmentId}`, config)
      .then((res) => {
        setSelectedUtilities(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // handle when the utility is selected
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedUtility, setSelectedUtility] = useState("");

  function handleHouseSelect(selectedHouseid) {
    setHouseSelectedId(selectedHouseid)

    // Find the selected house object based on its ID
    const selectedHouse = apartmentHouse.find((house) => house.house_id === parseInt(selectedHouseid));

    // Log the ID and house_number
    if (selectedHouse) {
      setHouseSelectedName(selectedHouse.house)
    }


    serverUrl.get(`/landlord/tenant-invoice/?house_id=${selectedHouseid}&reference=${selectedCategory}`, config)
      .then((res) => {
        // Filter invoices with status not equal to "paid"
        const unpaidInvoices = res.data.results.filter(invoice => invoice.status !== "paid");
        setInvoices(unpaidInvoices);
      })
      .catch((error) => {
        console.log(error);
      });

    // console.log('house selected', selectedHouseid)
  }
  // form data for tenant details ratings
  const [formDataPayment, setFormDataPayment] = useState({
    apartment: "",
    house_number: "",
    category: '',
    utility_type: '',
    source: '',
    amount: '',
    paid_date: '',
    phone_number: ''
  });
  // onchange functionality on rating tenant
  const handleChangePayment = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";

    if (name === "phone_number") {
      // Check if the entered number starts with '+254' and has at most 12 digits
      if (!value.startsWith("254") || value.length > 13 || isNaN(value.slice(4))) {
        errorMessage = "Invalid phone number format. Please enter a valid number starting with '254' and followed by up to 9 digits.";
      }
    }

    setFormDataPayment({
      ...formDataPayment,
      [name]: value,
      errorMessage: errorMessage
    });

  }




  const handleCategorySelect = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedUtility("");
  };

  // handle when the type of payment is selected
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const handlePaymentSelect = (e) => {
    setSelectedMethod(e.target.value);
    setSelectedPaymentMethod("");
  };


  // the invoice selected
  function handleInvoiceSelect(selectedInvoiceid) {
    setInvoiceSelectedId(selectedInvoiceid)

  }



  function handleSavePayment(e) {
    // prevent form from redirecting to another page
    e.preventDefault();
    const getCurrentTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    };
    // Add current time (hours, minutes, seconds)
    const currentTime = getCurrentTime();
    // get the details of the tenant
    const tenant = apartmentHouse.filter((house) => house.house === houseSelectedName && house.apartment === selectedApartmentId)
    const fullName = tenant[0].tenant;
    const firstName = fullName.split(' ')[0];

    const formData = {
      transcation_type: selectedMethod,
      transcation_id: selectedMethod + houseSelectedName,
      bill_ref_number: houseSelectedName,
      apartment: selectedApartmentId,
      transcation_time: currentTime,
      transcation_amount: formDataPayment.amount,
      phone_number: formDataPayment.phone_number,
      first_name: firstName,
    };



    // post the data to the ctob
    // serverUrl.post(`/landlord/c2btranscation/`, formData, config)
    //   .then((res) => {
    //     setShowModalPayment(false)
    //     // window.location.reload()
    //     const [month, year] = invoiceSelectedId.split('-');
    //     serverUrl.get(`/landlord/reconcile_transactions/?c2btransaction_id=${res.data.id}&house_id=${houseSelectedId}&payment_category=${selectedCategory}&expense_id=${selectedUtility}&invoice_month=${month}&invoice_year=${year}`, config)
    //       .then((res) => {
    //       })
    //       .catch((error) => {
    //         console.log(error)
    //       })
    //     toast.success('Succesfully added the payment!')

    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })

  }

  const exportCSV = () => {
    const csvData = [
      ['Paid Number', 'Paid By', 'Amount Paid', 'Overflow Amount', 'Transaction Id', 'Transaction Type', 'Date Paid'],
      ...ctobTransactions.map((item) => [item.phone_number, item.first_name, item.transcation_amount, item.overflow_amount, item.transcation_id, item.transcation_type, item.transcation_time]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'overflow_report.csv');
  };

  const updateRecord = (recordId) => {
    // Make an API request to update the reconcile data
    const updateData = {
      overflow_amount: '0'
    }
    serverUrl.patch(`/landlord/c2btranscation/${recordId}/`, updateData, config)
      .then((res) => {
        toast.success('The Amount Is Succesfully Removed From Our Tables!')
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      });
  };

  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className='overflowReport'>
        <div className='tasks ' >
          <h2 style={{ color: "#B33771" }}>Overpayment Report</h2>

          <div className='d-flex justify-content-between'>

            <div>
              <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
              <Button className='btn btn-danger text-white d-none' >PDF <i className='bi bi-file-pdf-fill'></i></Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>

          <Table className='table'>
            <thead className='underline'>
              <th onClick={() => handleSort("phone_number")}>Paid Number<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("first_name")}>Paid By<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("bill_ref_number")}>Unit<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_amount")}>Amount Paid<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("overflow_amount")}>Overflow Amount<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_id")}>Transaction Id<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_type")}>Transaction Type<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_time")}>Date Paid<i className='bi bi-chevron-expand'></i></th>
              <th>Reconcile Transaction</th>
            </thead>
            <tbody>
              {displayedItems.map((Defaulter, index) => (
                <tr key={index}>
                  <td>{Defaulter.phone_number}</td>
                  <td>{Defaulter.first_name}</td>
                  <td>{Defaulter.bill_ref_number}</td>
                  <td>{Defaulter.transcation_amount}</td>
                  <td>{Defaulter.overflow_amount}</td>
                  <td>{Defaulter.transcation_id}</td>
                  <td>{Defaulter.transcation_type}</td>
                  <td>
                    {isNaN(new Date(Defaulter.transcation_time)) ? (
                      `${Defaulter.transcation_time.substr(6, 2)}-${Defaulter.transcation_time.substr(4, 2)}-${Defaulter.transcation_time.substr(0, 4)}`
                    ) : (
                      new Date(Defaulter.transcation_time).toLocaleDateString()
                    )}
                  </td>

                  {/* <td className='btn btn-success bg-success btn-sm text-white mt-0' onClick={updateReconcile}>Refund Overflow</td> */}
                  <td className='btn btn-success bg-success btn-sm text-white mt-0' onClick={() => handleOpenModal(Defaulter)}>Revise Overflow</td>

                  {/* <td className='btn btn-success bg-warning btn-sm text-white mt-3' onClick={() => handleOpenModal(Defaulter)}>Reconcile</td> */}
                </tr>

              ))}
            </tbody>
          </Table>

          {/* modal used in adding the transaction */}
          <Modal Modal show={showModal} onHide={handleCloseModal} >
            <Modal.Header closeButton>
              <Modal.Title>Revise Overflow</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-between align-self-auto'>
              <Button className='btn btn-sm btn-warning text-white' onClick={updateReconcile}>Refund Overflow</Button>
              <Button className='btn btn-sm btn-info text-white d-none' onClick={handleOpenModalPayment} >Reconcile</Button>
            </Modal.Body>
            <Modal.Footer>

              <Button variant="success" onClick={handleCloseModalReload} >
                DONE
              </Button>
            </Modal.Footer>

          </Modal>

          {/* reconciliation modal */}
          <Modal show={showModalPayment} onHide={handleCloseModalPayment}>
            <Modal.Header className='bg-primary text-white' closeButton>
              <Modal.Title>Reconcile Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSavePayment}>
                <Form.Select
                  size='sm'
                  className='formProperty'
                  value={selectedApartmentHouse}
                  onChange={(e) => handleApartmentSelect(e.target.value)}

                >
                  <option value="">Select Apartment</option>
                  {landlordApartment.map((landlord) => (
                    <option key={landlord.id} value={landlord.id}>
                      {landlord.name}
                    </option>
                  ))}
                </Form.Select>

                <Form.Select
                  size='sm'
                  className='formProperty'
                  value={selectedHouseId} // Update this to the selected house ID
                  onChange={(e) => handleHouseSelect(e.target.value)} // Update the selected house ID

                >
                  <option value="">Select House</option>
                  {apartmentHouse.map((landlord) => (
                    <option key={landlord.house_id} value={landlord.house_id}>
                      {landlord.house} - {landlord.tenant}
                    </option>
                  ))}
                </Form.Select>



                <Form.Select
                  size='sm'
                  className='formProperty'
                  value={selectedCategory}
                  onChange={handleCategorySelect}

                >
                  <option value="" disabled>Select Category</option>
                  <option value="expense">Utility</option>
                  <option value="rent">Rent</option>
                </Form.Select>

                {selectedCategory === "expense" && (
                  <Form.Select
                    size='sm'
                    className='formProperty'
                    value={selectedUtility}
                    onChange={(e) => setSelectedUtility(e.target.value)}
                    style={{ marginTop: "10px" }}
                  >
                    <option value="" disabled selected>Select Utility</option>
                    {selectedUtilities.map((utility) => (
                      <option key={utility.id} value={utility.id}>
                        {utility.title}
                      </option>
                    ))}

                  </Form.Select>

                )}

                <Form.Select
                  size="sm"
                  className="formProperty"
                  value={invoiceSelectedId}
                  onChange={(e) => handleInvoiceSelect(e.target.value)}
                >
                  <option value="">Month Invoice Payment</option>
                  {monthNames.map((month, index) => {
                    const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);
                    const currentYear = new Date().getFullYear(); // Get the current year

                    const hasInvoiceForMonth = invoices.some(invoice => invoice.month.toLowerCase() === month);

                    return (
                      <>
                        {hasInvoiceForMonth ? (
                          invoices.map((invoice) => (
                            invoice.month.toLowerCase() === month ? (
                              <option
                                key={invoice.id}
                                value={`${invoice.month}-${invoice.year}`} // Include both month and year
                                style={{ color: invoice.status === "unpaid" ? 'red' : 'orange' }}
                              >
                                {invoice.month} - {invoice.year} - {invoice.status} - {invoice.amount}
                              </option>
                            ) : null
                          ))
                        ) : (
                          <option key={month} value={`${month}-unknown`}>
                            {formattedMonth} - {currentYear}
                          </option>
                        )}
                      </>
                    );
                  })}
                </Form.Select>


                <input
                  className="input-field"
                  type="text"
                  name="phone_number"
                  placeholder='Enter Phone Number'
                  value={formDataPayment.phone_number}
                  onChange={handleChangePayment}
                  required
                  style={{ borderRadius: '5px', border: '1px solid lightgray', marginTop: '1rem', outline: '0' }}
                />
                {formDataPayment.errorMessage && (
                  <p style={{ color: 'red' }}>{formDataPayment.errorMessage}</p>
                )}
                <div className="input-container">
                  <div className="placeholder-text mt-3 mb-2" style={{ fontSize: '0.9rem' }}>Choose Payment Date</div>

                  <input
                    className="input-field"
                    type="date"
                    name="paid_date"
                    value={formDataPayment.paid_date}
                    onChange={handleChangePayment}
                    required
                    style={{ borderRadius: '5px', border: '1px solid lightgray', outline: '0' }}
                  />
                </div>
                <input
                  className="input-field"
                  type="number"
                  name="amount"
                  placeholder='Enter Amount'
                  value={formDataPayment.amount}
                  onChange={handleChangePayment}
                  required
                  style={{ borderRadius: '5px', border: '1px solid lightgray', marginTop: '1rem', outline: '0' }}
                />
                <Button className='loginBtn mt-3 ms-4' type='submit'>Save</Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleCloseModalPayment}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>



          {/* get the display of the items 5 per page */}
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${ctobTransactions.length}`}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>

          </div>
        </div>
      </div >
    </>
  )
}

export default OverflowReport